<template>
    <section class="page">
        <cnav :navindex="navindex"></cnav>

        <div class="section">
            <div class="section-content">
                <div class="login">
                    <div class="login-left">
                        <div class="top-title">营销技术提供商</div>
                        <div class="top-text">
                            <p>为客户提供销售增长的数字化营销解决方案</p>
                            <p>致力于企业的销售增长</p>
                        </div>
                    </div>
                    <div class="login-right">
                        <div class="login-con">
                            <div class="login-nav">
                                <div class="login-title" :class="{active: center_index==1}" @click="loginnav(1)">欢迎登录</div>
                                <!-- <div class="login-title" :class="{active: center_index==2}" @click="loginnav(2)">服务中心</div> -->
                                
                            </div>
                            <template v-if="!is_login">
                                
                             <!-- v-show="logini==1" -->
                                <div>

                                    <el-form :model="ruleForm" :rules="rules"
                                    status-icon
                                    ref="ruleForm" 
                                    label-position="left" 
                                    label-width="0px" 
                                    class="demo-ruleForm login-page">
                                            <el-form-item prop="username">
                                                <el-input type="text" 
                                                    v-model="ruleForm.account" 
                                                    auto-complete="off" 
                                                    placeholder="请输入手机号或邮箱"
                                                ></el-input>
                                            </el-form-item>
                                            <el-form-item prop="password">
                                                <el-input type="password" 
                                                    v-model="ruleForm.pass" 
                                                    auto-complete="off" 
                                                    placeholder="密码"
                                                ></el-input>
                                            </el-form-item>
                                            <el-form-item style="width:100%;">
                                                <el-button type="primary" style="width:100%;" @click="handleSubmit('ruleForm')" :loading="logining">登录</el-button>
                                            </el-form-item>
                                    </el-form>
                                </div>
                                <div v-show="logini==2">
                                    <el-form :model="ruleForm2" :rules="rules2"
                                    status-icon
                                    ref="ruleForm2" 
                                    label-position="left" 
                                    label-width="0px" 
                                    class="demo-ruleForm login-page">
                                            <el-form-item prop="username">
                                                <el-input type="text" 
                                                    v-model="ruleForm2.mobile" 
                                                    auto-complete="off" 
                                                    placeholder="手机号"
                                                ></el-input>
                                            </el-form-item>
                                            <el-form-item prop="mmcode">
                                                <el-col :span="12">
                                                  <el-input v-model="ruleForm2.mmcode" placeholder="短信验证码"></el-input>
                                                </el-col>
                                                <el-col :span="12" class="code-b">
                                                  <el-button type="text" :disabled="code_in" @click="sendcode">发送验证码 <span v-if="code_in">{{count}}</span></el-button>
                                                </el-col>
                                            </el-form-item>
                                            <el-form-item style="width:100%;">
                                                <el-button type="primary" style="width:100%;" @click="handleSubmit2('ruleForm2')" :loading="logining">登录</el-button>
                                            </el-form-item>
                                        
                                    </el-form>
                                </div>
                                <div v-if="logini==2" class="login-code"><span @click="login_type(1)">账号密码登录</span></div>
                                <div v-else-if="logini==1" class="login-code"><span @click="login_type(2)">短信验证码登录</span></div>
                            </template>
                            <template v-else>
                                <div class="have_login">
                                    <div v-if="center_index==1||admin.is_service==1">
                                        <div class="login-header">
                                            <div class="avatar-box">
                                                <div class="avatar" :class="{active:!admin.avatar_url}">
                                                    <el-image fit="contain" :src="admin.avatar_url">
                                                        <div slot="error" class="image-slot"></div>
                                                    </el-image>
                                                </div>
                                            </div>
                                            
                                            <div class="name">{{admin.name}}</div>
                                        </div>
                                        <el-button class="golink" @click="goCenter">{{center_index==1?'前往控制中心':'前往服务中心'}}</el-button>
                                        <div class="login-code"><span @click="switch_account()">切换账号</span></div>
                                    </div>
                                    <div class="no-server" v-else>
                                        <p>尚未开通服务合作</p>
                                        <p @click="goCooperation">可前往<span>渠道合作</span>申请</p>
                                    </div>
                                    
                                    
                                </div>
                            </template>
                        </div>
                        
                        <div class="register">
                            <p>没有聚码账号 ？</p>
                            <div class="register-a" @click="register"> 前往注册账号 ↗</div>
                        </div>
                    </div>
                    <!-- <div class="login-right">
                        <div class="login-con">
                            <div class="login-title">登录聚码控制中心</div>
                            
                            
                        </div>
                        <div class="register">
                            <p>没有聚码账号 ？</p>
                            <div class="register-a" @click="register"> 前往注册账号 ↗</div>
                        </div>
                    </div> -->
                </div>
            </div>




        </div>
        <cfooter></cfooter>
        
    </section>

</template>

<script>
export default {
    data(){
        var checkPhone = (rule, value, callback) => {
            if (!value) {
              return callback(new Error('手机号码不能为空'));
            } else {
              const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
              console.log(reg.test(value));
              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('请输入正确的手机号码'));
              }
            }
        };
        return {
            navindex:'index',

            logining: false,
            admin: {},
            ruleForm: {
                password: '',
            },
            
            rules: {
                account: [{ required: true, message: '请填写账号', trigger: 'blur'}],
                pass: [{ required: true, message: '请填写密码', trigger: 'blur'}],
            },
            ruleForm2:{},
            rules2: {
                mobile: [{required: true,validator: checkPhone, trigger: 'blur'}],
                mmcode: [{ required: true, message: '请填入收到的短信验证码', trigger: 'blur'}],
            },

            logini: '1',
            code_in:false,
            count: '',
            timer: null,
            is_login: false,
            center_index: 1
        }
    },
    mounted(){
        this.pageinit();
    },
    methods: {
        loginnav(index){
            this.center_index = index;
        },
        pageinit(){
            let _this = this;
            this.axios.post('/index/nologin/login_init')
            .then(function (res) {
                const data = res.data;
                if (!data.err) {
                    _this.$nextTick(async function () {
                        _this.logining = false;
                        if (data.extra.adminid > 0) {
                            _this.is_login = true;
                            _this.admin = data.extra;
							await sessionStorage.setItem('user', data.extra.name);
							await sessionStorage.setItem('login', 1);
                        }
                    })
                    
                } else {
                    _this.logining = false;
                    // _this.oper_con = res.message;
                    
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        },
        login_type(type){
            this.logini = type;
        },

        // 发送验证码
        sendcode(){
            let _this = this;
            if(this.ruleForm2.mobile){
                
                this.axios.post('/index/nologin/get_mmcode_login', {
                    mobile: _this.ruleForm2.mobile 
                })
                .then(function (msg) {
                    const res = msg.data;
                    if (!res.err) {
                        _this.$nextTick(function () {
                            _this.logining = false;
                            _this.$alert('验证码已发送到指定手机号码上，请注意查收！','成功提示',{
                              confirmButtonText: '确定'
                            });
                            _this.code_in = true;

                            const TIME_COUNT = 120;
                            if (!_this.timer) {
                                _this.count = TIME_COUNT;
                                _this.code_in = true;
                                _this.timer = setInterval(() => {
                                if (_this.count > 0 && _this.count <= TIME_COUNT) {
                                    _this.count--;
                                } else {
                                    _this.code_in = false;
                                    clearInterval(_this.timer);
                                    _this.timer = null;
                                }
                               }, 1000)
                            }
                            
                        })
                        
                    } else {
                        _this.logining = false;
                        // _this.oper_con = res.message;
                        
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
            }else{
                _this.$alert('请先输入有效手机号码','错误提示',{
                  confirmButtonText: '确定'
                });
            }
            
        },
        postcode(){
            let _this = this;
            let obj = {};
            let url = '';

            if(this.logini == 1){
                url = '/index/nologin/login_pwd';
                obj.account = this.ruleForm.account;
                obj.password = this.ruleForm.password;

            }else if(this.logini == 2){
                url = '/index/nologin/login_mmcode';
                obj = this.ruleForm2;
            }
            if(this.center_index == 1){//控制中心登录
                obj.auth = 'client';
            }else{
                obj.auth = 'service';//服务中心登录
            }
            this.axios.post(url, {
                login: obj
            })
            .then(function (res) {
                const data = res.data;
                if (!data.err) {
                    _this.$nextTick(async function () {
                        _this.logining = false;
                        var host = window.location.host;
                        var url = '';
                        //版本号
                        if(_this.center_index == 1){
							 // http://192.168.3.5:8080/v/gorex/client/
                            url = 'https://'+host+'/v/juma/client/index/intro?v=1.2';
                        }else{
                            url = 'https://'+host+'/v/juma/service/index/index?v=1.2';
                        }
						sessionStorage.setItem('user', _this.ruleForm2.username);
						await sessionStorage.setItem('login', 1);
						console.log(url,'window.location.replace')
                        window.location.replace(url);
                    })
                    
                } else {
                    _this.logining = false;
                    // _this.oper_con = res.message;
                    
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        },
        handleSubmit(formName){
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if(valid){
                    this.logining = true;
                    let sha256 = require("js-sha256").sha256;
                    let cookie = document.cookie.split("; ");
                    let accessid = '';
                    for (var val of cookie) {
                        var key = val.split("=");
                        if (key[0] == "accessid") {
                            accessid = key[1]
                        }
                    }
                    let pwd1 = sha256(_this.ruleForm.pass);
                    _this.ruleForm.password = sha256(pwd1 + accessid);
                    _this.postcode();
                    
                }else{
                    console.log('error submit!');
                    return false;
                }
            })
        },
        
        handleSubmit2(formName){
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if(valid){
                    this.logining = true;
                    
                    _this.postcode();
                    
                }else{
                    console.log('error submit!');
                    return false;
                }
            })
        },
        register(){
            this.$router.push({name: 'register'});
        },
        goCooperation(){
            this.$router.push({name: 'partners'});
        },
        async goCenter(){
			
            var host = window.location.host;
            var url = '';
            if(this.center_index == 1){
                url = 'https://'+host+'/v/juma/client/index/intro?v=1.2';
            }else{
                url = 'https://'+host+'/v/juma/service/index/index';
            }
			
			
            window.location.replace(url);
        },
        switch_account(){
            this.is_login = false;
        }
    }
};
</script>

<style scoped>
    .page{
        width: 100%;
        min-width: 1200px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        background-image: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/b66663.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: rgba(0,0,0,0.5);
        background-blend-mode: multiply;
    }
    .login{
        width: 100%;
        display: flex;
        /*align-items: center;*/
        margin-top: 225px;
        padding: 0 80px;
        /*margin-top: 40%;*/
        /*transform: translateY(-50%);*/
    }
    .login-left{
        flex: 1;
        /*display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;*/
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        margin-top: 100px;
    }
    .top-title{
        font-size: 24px;
        line-height: 24px;
        font-weight: bolder;
    }
    .top-text{
        font-size: 15px;
        font-weight: 300;
        padding-top: 6px;
    }
    .login-right{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 100px;
        background: #fff;
        border: solid 1px #E5E1E1;
        border-radius: 10px;
        padding-bottom: 30px;

    }

    .login-con{
        width: 400px;
        height: 300px;
        
        padding: 20px 49px 0 49px;
    }
    .login-nav{
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .login-title{
        width: 50%;
        line-height: 36px;
        font-size: 14px;
        /*font-weight: bolder;*/
        color: #C0C4CC;
        text-align: center;
        text-align: center;
        border-bottom: 4px solid #E5E1E1;
    }
    .login-title.active{
        color: #E79E0D;
        border-bottom: 4px solid #E79E0D;
    }
    /deep/.el-form-item{
        margin-bottom: 20px;
    }
    /deep/.el-input__inner{
        border-radius: 0;
        /*border: none;*/
        border-bottom: solid 1px #E5E1E1;
    }
    /deep/.el-button--primary{
        border: none;
        background-color: #169BD5;
        font-size: 13px;
        letter-spacing: 1px;
        margin-top: 10px;
    }
    /deep/.el-button--text{
        color: #169BD5;
        font-weight: 400;
    }
    /deep/.el-button.is-disabled.el-button--text{
        color: #C0C4CC;
    }
    .login-code{
        font-size: 13px;
        color: #169BD5;
        text-align: right;
        cursor: pointer;
    }
    .forget{
        font-size: 12px;
        color: #169BD5;
        /*margin-top: 40px;*/
    }
    .register{
        display: flex;
        align-items: center;
        margin-top: 30px;
        font-size: 12px;
        color: #333333;
    }
    .register-a{
        color: #169BD5;
        cursor: pointer;
    }
    .code-b{
        text-align: center;
    }

    .login-header{
        width: 100px;
        height: 100px;
        /*border: 1px solid red;*/
        /*border-radius: 100%;*/
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .avatar-box{
        width: 110px;
        position: relative;
    }
    .avatar-box::after{
        content: '';
        width: 110px;
        height: 2px;
        background: #eee;
        position: absolute;
        bottom: 0;
        z-index: 99999;
    }
    .avatar{
        width: 80px;
        height: 40px;
        border-radius: 40px 40px 0 0;
        
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        margin: auto;
    }
    .avatar.active{
        background: #eee;
    }
    
    
    .login-header .el-image{
        width: 80px;
        height: 80px;
        /*border-top-left-radius: 100%;
        border-top-right-radius: 100%;*/
    }
    .login-header .name{
        font-size: 18px;
        font-weight: bolder;
        margin-top: 4px;
    }
    .golink{
        width: 100%;
        border: none;
        background-color: #169BD5;
        font-size: 13px;
        letter-spacing: 1px;
        margin-top: 30px;
        color: #fff;
        margin-bottom: 20px;
    }
    .no-server{
        height: 187px;
        font-size: 14px;
        color: #999;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }
    .no-server span{
        color: #169BD5;
        padding: 0 8px;
    }
</style>